import { NavigateFunction } from 'react-router-dom';

import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  PRIMARY_BLUE,
  PRIMARY_GREEN,
  PRIMARY_RED,
} from 'commons/styles/colors';
import {
  ReplenishItemDestination,
  ReplenishItemProps,
  ReplenishTask,
} from 'commons/types';
import { defaultActionProcess } from 'commons/utils/defaultActionProcess';
import { updateTaskBySKUReplenishRequested } from 'redux-stores/reducers/pickingTaskReducer';
import {
  addAssignedReplenishTask,
  prepareReplenishTasks,
  removeAssignedReplenishTask,
  setAssignedReplenishTask,
  updateReplenishTaskBySkuCode,
} from 'redux-stores/reducers/replenishTaskReducer';
import { snackbarSetData } from 'redux-stores/reducers/utilityReducer';
import { AppDispatch } from 'redux-stores/store';
import { swipeRxWmsApiReplenishJob } from 'services/apis/SwipeRxWmsApiReplenishJob/SwipeRxWmsApiReplenishJob';
import { swipeRxWmsApiReplenishList } from 'services/apis/SwipeRxWmsApiReplenishList';
import { IRequestReplenishDTO } from 'services/apis/SwipeRxWmsApiReplenishList/types';

export const getReplenishTasksAction = createAsyncThunk(
  'replenishTask/getReplenishTaskAction',
  async (_params: { withRecommendation?: boolean }, { dispatch }) => {
    const { withRecommendation } = _params;
    defaultActionProcess(async () => {
      const data =
        await swipeRxWmsApiReplenishList.getReplenishList(withRecommendation);
      dispatch(setAssignedReplenishTask(data));
    }, dispatch);
    return undefined;
  },
);

export const actionSetAssignedReplenishTask = createAsyncThunk(
  'replenishTask/actionSetAssignedReplenishTask',
  async (_, { dispatch }) => {
    defaultActionProcess(async () => {
      const data = await swipeRxWmsApiReplenishList.assignReplenishTask();
      dispatch(setAssignedReplenishTask(data));
    }, dispatch);
    return undefined;
  },
);

export const actionAddAssignedReplenishTask = createAsyncThunk(
  'replenishTask/actionAddAssignedReplenishTask',
  async (_, { dispatch }) => {
    await defaultActionProcess(async () => {
      const data = await swipeRxWmsApiReplenishList.addReplenishTask();
      dispatch(addAssignedReplenishTask(data));
    }, dispatch);
    return undefined;
  },
);

export const actionRemoveAssignedReplenishTask = createAsyncThunk(
  'replenishTask/actionRemoveAssignedReplenishTask',
  async (replenishNumber: string, { dispatch }) => {
    defaultActionProcess(async () => {
      const data =
        await swipeRxWmsApiReplenishList.removeReplenishTask(replenishNumber);
      dispatch(removeAssignedReplenishTask(data));
    }, dispatch);
    return undefined;
  },
);

export const actionStartReplenishTask = createAsyncThunk(
  'replenishTask/startReplenishTaskSource',
  async (
    payload: { tasks: ReplenishTask[]; navigate: NavigateFunction },
    { dispatch },
  ) => {
    defaultActionProcess(async () => {
      const data = await swipeRxWmsApiReplenishList.startReplenishProcess(
        payload.tasks.map((task) => task.replenish_number),
      );
      if (data !== 'ok') {
        dispatch(
          snackbarSetData({
            open: true,
            message: data,
            color: PRIMARY_BLUE,
          }),
        );
      }
      payload.navigate(`/replenish-list/source`);
    }, dispatch);
    return undefined;
  },
);

export const setInitReplenishTaskSource = createAsyncThunk(
  'replenishTask/setInitReplenishTaskSource',
  async (_, { dispatch }) => {
    dispatch(prepareReplenishTasks());
  },
);

export const getSourceProductDetail = async (
  params: {
    skuCode: string;
    sourceBin: Pick<ReplenishItemProps, 'bin' | 'inventory_number'>;
  },
  dispatch: AppDispatch,
): Promise<ReplenishItemProps | undefined> => {
  const { skuCode, sourceBin } = params;
  const result = await defaultActionProcess(
    async () => {
      const response = await swipeRxWmsApiReplenishJob.getSourceProductDetail(
        skuCode,
        sourceBin,
      );
      return response;
    },
    dispatch,
    false,
  );
  return result;
};

export const getReplenishTaskBySkuCode = createAsyncThunk(
  'replenishTask/getReplenishTaskBySkuCode',
  async (
    _params: {
      skuCode: string;
      updateSourceRecommendation?: boolean;
      updateDestinationRecommendation?: boolean;
      retailBinInfo?: boolean;
      navigate: NavigateFunction;
    },
    { dispatch },
  ) => {
    const {
      skuCode,
      updateDestinationRecommendation = false,
      updateSourceRecommendation = false,
      retailBinInfo: retailBatchInfo = false,
      navigate,
    } = _params;

    defaultActionProcess(async () => {
      const response = await swipeRxWmsApiReplenishJob.getReplenishJobBySkuCode(
        {
          sku_code: skuCode,
          recommendation_max_count: 3,
          update_source_recommendation: updateSourceRecommendation,
          update_destination_recommendation: updateDestinationRecommendation,
          retail_bin_info: retailBatchInfo,
        },
      );

      if (response.sku_code !== skuCode) {
        navigate('/replenish-list/source');
        throw new Error('sku code not found');
      }
      dispatch(updateReplenishTaskBySkuCode(response));
    }, dispatch);
  },
);

export const postReplenishPickSourceBin = async (
  params: { skuCode: string; sourceBin: ReplenishItemProps },
  dispatch: AppDispatch,
): Promise<void> => {
  const { skuCode, sourceBin } = params;
  defaultActionProcess(
    async () => {
      await swipeRxWmsApiReplenishJob.postPickSourceBin(skuCode, sourceBin);
    },
    dispatch,
    false,
  );
};

export const postRemoveSourceBatch = async (
  params: {
    skuCode: string;
    removedBin: Pick<ReplenishItemProps, 'bin' | 'inventory_number'>;
  },
  dispatch: AppDispatch,
): Promise<void> => {
  const { skuCode, removedBin } = params;
  defaultActionProcess(
    async () => {
      await swipeRxWmsApiReplenishJob.postRemoveSourceBatch(
        skuCode,
        removedBin,
      );
    },
    dispatch,
    false,
  );
};

export const postReplenishPutDestinationBin = async (
  params: { skuCode: string; destinationBin: ReplenishItemDestination },
  dispatch: AppDispatch,
): Promise<void> => {
  const { skuCode, destinationBin } = params;
  defaultActionProcess(
    async () => {
      await swipeRxWmsApiReplenishJob.postPutDestinationBin(
        skuCode,
        destinationBin,
      );
    },
    dispatch,
    false,
  );
};

export const postRemoveDestinationBatch = async (
  params: {
    skuCode: string;
    removedBin: Pick<ReplenishItemDestination, 'bin' | 'inventory_number'>;
  },
  dispatch: AppDispatch,
): Promise<void> => {
  const { removedBin, skuCode } = params;
  defaultActionProcess(
    async () => {
      await swipeRxWmsApiReplenishJob.postRemoveDestinationBatch(
        skuCode,
        removedBin,
      );
    },
    dispatch,
    false,
  );
};

export const postCompleteReplenish = async (): Promise<{
  open: true;
  message: string;
  color: string;
  success: boolean;
}> => {
  const result = await swipeRxWmsApiReplenishJob.postCompleteReplenish();
  if (!result) {
    return {
      open: true,
      message: 'Replenish Task Failed',
      color: PRIMARY_RED,
      success: false,
    };
  }
  return {
    open: true,
    message: 'Replenish Task Processed',
    color: PRIMARY_GREEN,
    success: true,
  };
};

export const requestReplenish = createAsyncThunk(
  'replenishTask/requestReplenish',
  async (payload: IRequestReplenishDTO, { dispatch }) => {
    return defaultActionProcess(async () => {
      try {
        await swipeRxWmsApiReplenishList.requestReplenish(payload);
        dispatch(
          snackbarSetData({
            open: true,
            message: 'Successfully request to replenish SKU',
            color: PRIMARY_GREEN,
          }),
        );
      } catch (error: any) {
        if (
          !error.message.includes(
            'Task already created and in progress for sku',
          )
        ) {
          throw error;
        }
        dispatch(
          snackbarSetData({
            open: true,
            message: error.message,
            color: PRIMARY_BLUE,
          }),
        );
      }
      const requestedPayload = {
        sku_code: payload.sku_code,
        replenish_requested: true,
      };
      dispatch(updateTaskBySKUReplenishRequested(requestedPayload));
    }, dispatch);
  },
);
