import React from 'react';

import { PRIMARY_RED, PRIMARY_YELLOW } from 'commons/styles/colors';
import { TableConfig } from 'commons/types';

import { IDestinationSummary } from '../types';

export const destinationSummaryConfig: TableConfig = [
  {
    header: 'Total Requested Qty',
    key: 'total_requested_quantity',
    textAlign: 'center',
    headerTextAlign: 'center',
  },
  {
    header: 'Source Picked Qty',
    element: (item: IDestinationSummary): React.ReactElement => {
      if (item.source_picked_quantity < item.total_requested_quantity) {
        return (
          <div style={{ color: `${PRIMARY_YELLOW}`, textAlign: 'center' }}>
            {item.source_picked_quantity}
          </div>
        );
      }
      return (
        <div style={{ textAlign: 'center' }}>{item.source_picked_quantity}</div>
      );
    },
    headerTextAlign: 'center',
  },
  {
    header: 'Replenished Qty',
    element: (item: IDestinationSummary): React.ReactElement => {
      if (item.replenished_quantity < item.source_picked_quantity) {
        return (
          <div style={{ color: `${PRIMARY_YELLOW}`, textAlign: 'center' }}>
            {item.replenished_quantity}
          </div>
        );
      } else if (item.replenished_quantity > item.source_picked_quantity) {
        return (
          <div style={{ color: `${PRIMARY_RED}`, textAlign: 'center' }}>
            {item.replenished_quantity}
          </div>
        );
      }
      return <div>{item.replenished_quantity}</div>;
    },
    headerTextAlign: 'center',
  },
];
