import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Add, Close } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';

import { Header } from 'commons/components/Header';
import { TableComponent } from 'commons/components/Table';
import { ReplenishTaskStatusEnum } from 'commons/enums';
import { PartialReplenishTask, ReplenishTask } from 'commons/types';
import { getReplenishTasksAction } from 'redux-stores/actions';
import {
  actionAddAssignedReplenishTask,
  actionRemoveAssignedReplenishTask,
  actionSetAssignedReplenishTask,
  actionStartReplenishTask,
} from 'redux-stores/actions/replenishTask.action';
import { selectUser } from 'redux-stores/features/authSlice';
import { RootReducerInterface } from 'redux-stores/reducers';
import { AppDispatch } from 'redux-stores/store';

import S from './AssignSKUPage.styles';
import AssignSKUDialog from './components/AssignSKUDialog.modal';
import RemoveSKUDialog from './components/RemoveSKUDialog.modal';
import { config } from './config';

const AssignSKUPage: React.FC = () => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { assignedTasks } = useSelector(
    (state: RootReducerInterface) => state.replenishTask,
  );
  const [openAssignModal, setOpenAssignModal] = useState<boolean>(false);
  const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
  const [removedTask, setRemovedTask] = useState<ReplenishTask | null>(null);

  useEffect(() => {
    dispatch(getReplenishTasksAction({}));
  }, [dispatch]);

  const onAssignModalOpen = () => setOpenAssignModal(true);
  const onAssignModalClose = () => setOpenAssignModal(false);
  const assignSKU = async () => {
    if (assignedTasks.length === 0) {
      dispatch(actionSetAssignedReplenishTask());
    } else {
      dispatch(actionAddAssignedReplenishTask());
    }
    setOpenAssignModal(false);
  };

  const startReplenish = async () =>
    dispatch(actionStartReplenishTask({ navigate, tasks: assignedTasks }));

  const onRemoveModalOpen = (task: ReplenishTask) => {
    setOpenRemoveModal(true);
    setRemovedTask(task);
  };
  const onRemoveModalClose = () => setOpenRemoveModal(false);
  const removeSKU = async (task: PartialReplenishTask) => {
    dispatch(actionRemoveAssignedReplenishTask(task.replenish_number));
    setOpenRemoveModal(false);
  };

  const handleBackToHomePage = (): void => {
    navigate('/');
  };

  const renderSkuList = (tasks: ReplenishTask[]) =>
    tasks.map((task) => (
      <S.GridWrapper container>
        <Grid
          item
          xs={1}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
          }}
        >
          <IconButton onClick={() => onRemoveModalOpen(task)}>
            <Close fontSize="small" />
          </IconButton>
        </Grid>

        <Grid item xs={11}>
          <S.SkuNumberGridWrapper container>
            <S.SkuInfoGrid item xs={5}>
              <p>
                {task.is_high_priority ? (
                  <span style={{ fontWeight: '900', fontSize: '1.5em' }}>
                    !{' '}
                  </span>
                ) : (
                  ''
                )}
                SKU {task.sku_code}
              </p>
              <S.SkuMinBinQtyGrid>
                {task.destination_bin_requested.find((bin) => !bin.bin)
                  ? `Requested QTY: ${task.destination_bin_requested.reduce(
                      (sum, bin) => sum + bin.requested_quantity,
                      0,
                    )}`
                  : `Min Bin QTY: ${
                      task.product?.quantity_threshold?.[user.warehouseId]
                        .min_bin_qty || '-'
                    }`}
              </S.SkuMinBinQtyGrid>
            </S.SkuInfoGrid>
            <S.SkuInfoGrid className="priority" item xs={7}>
              <S.SkuTypeInfoGrid
                style={{ textTransform: 'uppercase' }}
                className={task.type}
              >
                {task.type.replace('_', ' ')}
              </S.SkuTypeInfoGrid>
            </S.SkuInfoGrid>
          </S.SkuNumberGridWrapper>
          <S.BinInfoGridWrapper container>
            <S.BinInfoGrid item xs={12}>
              {task.destination_bin_requested.find((bin) => bin.bin) ? (
                <TableComponent
                  config={config}
                  data={task.destination_bin_requested.filter((bin) => bin.bin)}
                />
              ) : null}
            </S.BinInfoGrid>
          </S.BinInfoGridWrapper>
        </Grid>
      </S.GridWrapper>
    ));

  return (
    <div>
      <Header title="Replenish" prevPageHandler={handleBackToHomePage} />
      {/* Content Section */}
      <S.ContentWrapper>
        <S.SubtitleWrapper container>
          <S.Subtitle item xs={12}>
            Assign SKU
          </S.Subtitle>
        </S.SubtitleWrapper>

        {renderSkuList(assignedTasks)}

        <S.AddSKUButtonWrapper>
          <S.SecondaryButton onClick={onAssignModalOpen}>
            <Add /> {assignedTasks.length > 0 ? 'GET ANOTHER SKU' : 'GET SKU'}
          </S.SecondaryButton>
        </S.AddSKUButtonWrapper>
        <S.BlankWhiteSpace />
      </S.ContentWrapper>

      {/* Footer Section */}
      <S.FooterWrapper>
        <S.PrimaryButton disabled={false} onClick={startReplenish}>
          START TASK
        </S.PrimaryButton>
      </S.FooterWrapper>

      {openAssignModal && (
        <AssignSKUDialog
          onClose={onAssignModalClose}
          openModal={openAssignModal}
          onConfirm={assignSKU}
        />
      )}

      {openRemoveModal && (
        <RemoveSKUDialog
          task={removedTask}
          onClose={onRemoveModalClose}
          openModal={openRemoveModal}
          onConfirm={removeSKU}
        />
      )}
    </div>
  );
};

export default AssignSKUPage;
