import React from 'react';

import { ReplenishItemProps, TableConfig } from 'commons/types';
import { formatDate } from 'commons/utils/formatDate';

export const destinationSourceConfig: TableConfig = [
  {
    header: 'Source Bin',
    key: 'bin',
  },
  {
    header: 'Batch',
    key: 'inventory_number',
  },
  {
    header: 'Expiry Date',
    element: (item: ReplenishItemProps): React.ReactElement => {
      const date = formatDate(item.expiry_date);
      return <div>{date}</div>;
    },
  },
  {
    header: 'Qty',
    key: 'quantity',
    textAlign: 'center',
    headerTextAlign: 'center',
  },
];
