import React from 'react';

import { capitalize } from '@mui/material';

import {
  ReplenishBinRequestedWithMaxBinQuantity,
  TableConfig,
} from 'commons/types';

export const destinationRecommendationConfig: TableConfig = [
  {
    header: 'Bin In Need',
    key: 'bin',
  },
  {
    header: 'Current / Max Bin Qty',
    element: (
      item: ReplenishBinRequestedWithMaxBinQuantity,
    ): React.ReactElement => {
      return (
        <div style={{ textAlign: 'center' }}>
          {item.current_quantity} / {item.max_bin_qty}
        </div>
      );
    },
    headerTextAlign: 'center',
  },
  {
    header: 'Type',
    element: (
      item: ReplenishBinRequestedWithMaxBinQuantity,
    ): React.ReactElement => {
      const binTypeText = capitalize((item.bin_type || '').replace('_', ' '));
      return <div>{binTypeText}</div>;
    },
  },
  {
    header: 'Qty Requested',
    key: 'requested_quantity',
    textAlign: 'center',
    headerTextAlign: 'center',
  },
];
