/* eslint-disable react/no-array-index-key */
import React from 'react';

import {
  DataTableConfig,
  TableConfig,
} from '../../types/tableConfig.interface';

import * as S from './Table.styles';

interface TableProps {
  config: TableConfig;
  data: DataTableConfig;
  onRowClick?: (item: any) => void;
  additionalTableConfig?: {
    bodyFontSize: string;
  };
}

export const TableComponent: React.FC<TableProps> = ({
  data,
  config,
  onRowClick,
  additionalTableConfig,
}) => (
  <S.TableWrapper>
    <S.TableHeader>
      <tr>
        {config.map((c) => {
          if (c.header)
            return (
              <S.TableHeaderRow
                style={{
                  textAlign: c.headerTextAlign || 'left',
                }}
              >
                {c.header}
              </S.TableHeaderRow>
            );
          return (
            <S.TableHeaderRow
              style={{
                textAlign: c.headerTextAlign || 'left',
              }}
            >
              {c.headerElement}
            </S.TableHeaderRow>
          );
        })}
      </tr>
    </S.TableHeader>
    <S.TableBody fontSize={additionalTableConfig?.bodyFontSize}>
      {data.map((item, key) => (
        <S.TableRow key={key} onClick={() => onRowClick?.(item)}>
          {config.map((c) => {
            if (c.key)
              return (
                <S.TableColumn style={{ textAlign: c.textAlign || 'left' }}>
                  {item[c.key] || c.defaultValue}
                </S.TableColumn>
              );

            // if no key provided, use element
            return <S.TableColumn>{c.element?.(item)}</S.TableColumn>;
          })}
        </S.TableRow>
      ))}
    </S.TableBody>
  </S.TableWrapper>
);
